export default {
    "app":{
        "name":"LanaPlus"
    },
    "common":{
        "loading":"loading...",
        "days":"Days",
        "day":"días",
        "money_icon":"$",
        "confirm":"CONFIRM",
        "pay_full_amount":"Pay Full Amount",
        "loan_extension":"Loan Extension",
        "service_phone_title":"Atención al cliente: ",
        "service_phone":"+52-5585263380",
        "amount":"Repayment Amount",
        "date":"Repayment Date",
        "loan_amount":"Loan Amount",
        "interest":"Interest",
        "late_fee":"Late Fee",
        "ok":"OK",
        "error":"Unknown exception",
        "network_err":"There's something wrong with the network",
        "refresh":"REFRESH",
        "error_404":"404",
        "error_data":"500",
        "error_msg":"Unknown error,please conntact customer service:",
        "hint_1":"Your loan is granted by a RBI registered NBFC.",
        "hint_2":"Rhino Finance Pvt Ltd",
        "btn":"Continuar"
    },
    "login": {
        "hint_phone": "Enter your mobile number",
        "hint_code": "Enter OTP",
        "get_otp": "Get OTP",
        "code_resend": "Resend",
        "login_btn": "Login",
        "phone_err": "Please enter a valid mobile number.",
        "code_err": "Please enter a valid OTP.",
        "login_agreement_1":"By proceeding, you agree to our ",
        "login_agreement_2":"Terms & Conditions ",
        "login_agreement_3":"and ",
        "login_agreement_4":"Privacy Policy. ",
        "login_agreement_5":"Standard operator charges may apply for SMS.",
        "check_code_success":"Your OTP has been sent.",
        "check_code_error":"Sending failed. Please try again",
        "login_token_error":"Login expired, please login again"
    },
    "sign":{
        "sign_title":"Estimado usuario,por favor confirmarque usted está haciendo pagos para:",
        "sign_name":"Nombre:",
        "sign_phone":"Teléfono móvil:",
        "sign_agreement":"Asegúrese de que la información anterior sea correcta. No realice un pago si la información no es correcta.",
        "sign_btn":"CONFIRMAR Y CONTINUAR"
    },
    "overdue":{
        "overdue_title":"Repayment Overdue",
        "repayment_data":"Repayment Date",
        "repayment_days":"Overdue Days",
        "pop_title":"COVID-19",
        "pop_content":"ReliefAs we continue to navigate these uncertain times, the safety and well-being of our customers and employees remains our top priority. We understand some of our customers may have a hard time making the full payment. So we came up with a Loan Extension option for customer to make a much lower payment and extend their loan due date.OK"
    },
    "normal":{
        "normal_title":"Repayment Amount"
    },
    "nobill":{
        "nobill_title":"No Payment Due",
        "go_google":"Get our APP for more functions"
    },
    "repayment":{
        "title":"Reembolso",
        "title_2":"Monto de pago",
        "title_3":"Extender el Reembolso",
        "bank_title":"Transferencia bancaria",
        "oxxo_title":"Pago de OXXO",
        "msg_1":"Puede transferir fondos a las siguientes cuentas bancarias de la forma que más le convenga:",
        "msg_2":"REFERENCIA",
        "msg_4":"INSTRUCCIONES",
        "msg_5":"1. Acude a la tienda OXXO más cercana. Encuéntrala aqui.",
        "msg_6":"2. Indica en caja que quieres realizar un pago de OXXOPay.",
        "msg_7":"3. Dicta al cajero el número de referencia en esta ficha para que la tecleé directamente en la pantalla de venta.",
        "msg_8":"4. Realiza el pago correpondiente con dinero en efectivo. ",
        "msg_9":" 5. Al confirmar tu pago, el cajero te entregará un comprobante impreso. En él podrás verificar que se haya realizado correctamente. Conserva este comprobante de pago.",
        "nombre":"Nombre",
        "tipo":"Tipo de cuenta:",
        "clabe":"CLABE:",
        "stp":"Destinatario:",
        "concepto":"Concepto:",
        "msg_3":"Sugerencia: puede hacer clic en el icono de la derecha para copiar la información relacionada!",
    },
    "repayment_result":{
        "success":"Repayment succes",
        "failure":"Repayment failure",
        "back_home":"Back Home",
        "fail_msg":"Please ensure that your bank balance is sufficient and try again. please contact customer service if you encounter failures many times."
    },
    "home":{
        "title_1":"Bienvenido a",
        "title_2":"Lana +",
        "title_3":"Reembolso en curso",
        "title_4":"Plazo del préstamo",
        "repayment_title":"Cantidad del Préstamo",
        "repayment_pay_date":"Fecha de pago",
        "overdue":"Monto de pago tardío",
        "conision_no":"Comisión:No",
        "home_btn_1":"Reembolso Completo",
        "home_btn_2":"Extender el Reembolso",
    },
    "diglog":{
        "title":"Advertencia",
        "msg":"Debe transferir la cantidad correcta de dinero a la cuenta designada para extender su préstamo. No se reconocerá más o menos dinero y perderá la posibilidad de extensión del préstamo. Además, el dinero que se muestra aquí es funcional por hoy. Necesita generar una nueva cantidad para los próximos días.",
        "btn":"CONFIRMAR"
    },
    "no_extension":{
        "adeudado":"Adeudado",
        "repayment_date_msg":"Fecha de reembolso actual",
        "repayment_btn_msg":"Ir a pagar $",
    },
    "extension":{
        "card_title":"Extienda el período",
        "btn1":"Pague $ ",
        "btn2":" para extender",
        "overdue_msg":"Por su buen historial crediticio, al escoger extender su periodo de préstamo también podrá obtener un descuento del monto de pago tardío."

    }

};
