<template>
    <div class="box_main">
        <div class="top theme_bg">
            <span class="app_name_1">{{$t("app.name")}}</span>
        </div>
        <div class="content" v-if="loading">
            <div class="fragment" v-if="isRepayState">
                <NoExtension :loan-info="loanState" :repay-info="repay" v-if="!repay.rollOverAble"/>
                <Extension :loan-info="loanState" :repay-info="repay" v-else/>
            </div>
            <div class="fragment" v-else>
                <NoRepay/>
            </div>
            <div class="service_box">
                <div class="service">
                    <span>{{$t("common.service_phone_title")}}</span>
                    <span class="phone">{{$t("common.service_phone")}}</span>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import API from "@/api";
    import {pagePoint} from "@/utils/PagePoint";
    import NoExtension from "@/views/normal_ postpone/components/NoExtension";
    import Extension from "@/views/normal_ postpone/components/Extension";
    import NoRepay from "@/views/normal_ postpone/components/NoRepay";
    const EVENT_ID = "h5_loan_repay";
    export default {
        name: "Home",
        components:{
            NoExtension,
            Extension,
            NoRepay
        },
        mounted(){
            this.phone = this.$store.state.phone;
            if(this.phone===""||this.phone==="undefined"){
                this.phone = localStorage.getItem("phone");
            }
            console.log("phone--->"+this.phone)
            //获取用户最近一笔数据
            this.getLatestInfo();
        },
        created(){
            pagePoint(EVENT_ID,"enter_the_page","in",(new Date()).valueOf());
        },
        data(){
            return{
                loading:false,//是否加载完毕
                selectBank:"1",
                phone:"",
                isRepayState:false,//是否是还款状态
                isOverdue:false,//是否逾期
                fees:[{
                    "displayOrder": 0,
                    "displayName": "",
                    "value": 0,
                    "displayValue": 0
                }],
                feeDetail: {
                    "loanNo": "",
                    "loanAmount": "",
                    "payment": "",
                    "repayment": "",
                    "overDueAmt": null,
                    "fees": [{
                        "displayOrder": 0,
                        "displayName": "",
                        "value": 0,
                        "displayValue": 0
                    }]
                },
                loanDateInfo: {
                    "loanDays": 0,
                    "currentInstNum": 0,
                    "currentRepayDate": ""
                },
                loanState:{
                    "loanStat": "",//REPAYMENT才显示按钮
                    "certStatus": null,//暂时没用
                    "loanInfoData": {
                        "loanNo": "",
                        "custNo": "",
                        "instNum": "",
                        "prodNo": "",
                        "bankNo": "",
                        "bankCode": "",
                        "bankOrgName": "",
                        "overdueStatus": false,
                        "loanDateInfo": {
                            "loanDays": 1,
                            "currentInstNum": 1,
                            "currentRepayDate": ""
                        },
                        "feeDetail": {
                            "loanNo": "",
                            "loanAmount": "",
                            "payment": "",
                            "repayment": "",
                            "overDueAmt": null,
                            "fees": [{
                                "displayOrder": 0,
                                "displayName": "22",
                                "value": 0,
                                "displayValue": 5
                            }]
                        },
                        "reapplyDate": null
                    },
                    "rollOverDetails": {
                        "rollOverAmt":"",
                        "rollOverDay":"",
                        "rollOverRepayDay":"",
                        "nextLoanRepaymentAmt":"",
                        "detailList":[
                            {
                                "originalValue":"",
                                "value":"",
                                "displayName":"",
                                "percent":"",
                                "fixedVal":"",
                            }
                        ]
                    },//展期数据
                    "rollOverAble":false,//是否展期
                },
                repay:{
                    custNo:"",
                    loanNo :"",
                    paymentMethod:"STP",
                    repayDate:"",
                    repayInstNum:"",
                    transAmt:"",
                    isOverdue:false,//是否逾期
                    rollOverAble:false,//是否展期
                    //展期数据
                    rollOverDetails:{
                        rollOverAmt:"",
                        rollOverDay:"",
                        rollOverRepayDay:"",
                    },
                    //下一次展期还款数据
                    nextLoanRepaymentAmt:""
                }
            }
        },
        methods:{
            getLatestInfo(){
                if(this.phone){
                    let that = this;
                    API.lanaplus.getLatestInfo(this.phone).then((res)=>{
                        console.log(res);
                        that.loading = true;
                        that.loanState = res.data;
                        //赋值
                        let pageStatus = "";
                        if(res&&res.data){
                            let data = res.data;
                            that.isRepayState = data.loanStat === "REPAYMENT";
                            if(data.loanInfoData){
                                //逾期状态
                                that.isOverdue = data.loanInfoData.overdueStatus;
                                that.repay.isOverdue = data.loanInfoData.overdueStatus;
                                that.repay.custNo = data.loanInfoData.custNo;
                                that.repay.loanNo = data.loanInfoData.loanNo;
                                if(data.loanInfoData.loanDateInfo){
                                    that.loanDateInfo = data.loanInfoData.loanDateInfo;
                                    that.repay.repayInstNum = data.loanInfoData.loanDateInfo.currentInstNum;
                                    that.repay.repayDate = data.loanInfoData.loanDateInfo.currentRepayDate;
                                }
                                if(data.loanInfoData.feeDetail){
                                    that.feeDetail = data.loanInfoData.feeDetail;
                                    that.fees = data.loanInfoData.feeDetail.fees.sort(that.compare('displayOrder'));
                                    that.repay.transAmt = data.loanInfoData.feeDetail.repayment;
                                }else{
                                    that.fees = [];
                                }
                            }
                            if(data.rollOverDetails){
                                //设置展期数据
                                that.repay.rollOverDetails = data.rollOverDetails;
                                //设置下一次展期还款数据
                                that.repay.nextLoanRepaymentAmt = data.rollOverDetails.nextLoanRepaymentAmt;
                                //是否逾期
                                if(that.isOverdue){
                                    pageStatus = "overdue_with_extension";
                                }else{
                                    pageStatus="normal_with_extension";
                                }
                            }else{
                                //是否逾期
                                if(that.isOverdue){
                                    pageStatus = "overdue_no_extension";
                                }else{
                                    pageStatus="normal_no_extension";
                                }
                            }
                            //上传拍点
                            pagePoint(EVENT_ID,"enter_the_page","page_status",pageStatus);
                            that.repay.rollOverAble = data.rollOverAble;
                            //保存到vuex
                            that.$store.dispatch("saveRepayInfo",that.repay);
                            console.log("vuex:"+JSON.stringify(that.$store.state.repayInfo))
                        }
                    }).catch(res=>{
                        console.log(res);
                        that.loading = true;
                        that.$toast(that.$t('common.error'));
                    });
                    //跳转路由 最近一笔贷款
                    // this.$router.push({path: '/payment/latest',replace: true});
                    // this.$router.replace({path: '/payment/latest'});
                }else{
                    this.showToast(this.$t("common.error"))
                }
            },
            goRepayment(isRollOverAble){
                console.log("是否展期"+isRollOverAble);
                if(isRollOverAble===1){
                    pagePoint(EVENT_ID,"click_normal_repay","click",(new Date()).valueOf());
                    //不是展期
                    if(this.selectBank==="1"){
                        this.repay.paymentMethod = "STP";
                        this.repay.rollOverAble = false;
                        console.log(this.repay)
                        //保存到vuex
                        this.$store.dispatch("saveRepayInfo",this.repay);
                        this.$router.push({path: '/bankrepayment'});
                    }else{
                        this.repay.paymentMethod = "CONEKTA";
                        this.repay.rollOverAble = false;
                        //保存到vuex
                        this.$store.dispatch("saveRepayInfo",this.repay);
                        console.log(this.repay)
                        this.$router.push({path: '/oxxoRepayment'});
                    }
                }else{
                    pagePoint(EVENT_ID,"click_extension_repay","click",(new Date()).valueOf());
                    if(this.selectBank==="1"){
                        this.repay.paymentMethod = "STP";
                        this.repay.rollOverAble = true;
                        console.log(this.repay)
                        //保存到vuex
                        this.$store.dispatch("saveRepayInfo",this.repay);
                        this.$router.push({path: '/bankrepayment'});
                    }else{
                        this.repay.paymentMethod = "CONEKTA";
                        this.repay.rollOverAble = true;
                        //保存到vuex
                        this.$store.dispatch("saveRepayInfo",this.repay);
                        console.log(this.repay)
                        this.$router.push({path: '/oxxoRepayment'});
                    }
                }

            },
            //排序
            compare(property){
                return function(a,b){
                    let value1 = a[property];
                    let value2 = b[property];
                    let v = value1 - value2;
                    if(v<0){
                        return -1;
                    }else if(v>0){
                        return 1;
                    }
                    return 0;
                }
            },
            uploadRadioPoint(index){
                if(index===1){
                    pagePoint(EVENT_ID,"click_STP","click",(new Date()).valueOf());
                }else{
                    pagePoint(EVENT_ID,"click_OXXO","click",(new Date()).valueOf());
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .text_red{
        color: #f00 !important;;
    }
    span{
        font-family: "Roboto-Regular";
    }
    .div_row{
        display: flex;
        flex-direction: row;
        width: 100%;
        font-font: Roboto-Medium;
    }
    .div_col{
        display: flex;
        flex-direction: column;
        width: 100%;
        font-font: Roboto-Medium;
    }
    .bank_img{
        width: 100px;
        height: 40px;
    }
    .box_main{
        width: 100vw;
        height: 100vh;
        background: #F6F7F7;
        .top{
            width: 100vw;
            height: 30vh;
            display: flex;
            flex-direction: column;
            align-items: center;
            background: $theme_color_green;
            .app_name_1{
                display: block;
                font-size: 21px;
                font-weight: 600;
                color: #fff;
                font-family: 'Roboto-Medium';
                padding-top: 5vh;
                letter-spacing: 2px;
            }
            .item{
                width: 80%;
                margin-left: 10%;
                display: flex;
                flex-direction: row;

            }
        }
        .content{
            width: 90vw;
            height: auto;
            padding-bottom: 30px;
            position: absolute;
            top:15vh;
            left: 5vw;
            .title{
                width: 100%;
                text-align: center;
                color: #fff;
                font-size: 15px;
                color: $red_color;
                margin-top: 35px;
                font-family: "Roboto-Bold";
                font-width: 600;
            }
            .items{
                display: flex;
                flex-direction: column;
                width: 80%;
                margin-left: 10%;
                margin-top: 30px;
                .item{
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    font-family: "Roboto-Regular";
                    font-size: 16px;
                    font-weight: 600;
                }
            }
            .btn{

                font-size:14px;
                font-family: "Roboto-Regular";
                height: 45px;
                border-radius: 5px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }
            .btn1{
                color: #fff;
                background-image: linear-gradient(to right, $theme_color_green , $theme_color_green);

            }
            .btn2{
                color: #3D3D3D;
                background-image: linear-gradient(to right, $theme_color , $theme_color);
            }
        }
        .service_box{
            width: 100vw;
            text-align: center;
            font-size: 12px;
            font-family: "Roboto-Medium";
            color: #999999;
            width: 100%;
            display: flex;
            margin-top: 20px;
            justify-content: center;
            align-items: center;
            /*position: absolute;*/
            /*bottom: 40px;*/
        }
        .service{
            width: 100vw;
            text-align: center;
            font-size: 12px;
            font-family: "Roboto-Medium";
            color: #999999;
            .phone{
                color: $theme_color_green;
                margin-left: 5px;
            }
        }
        .fragment {
           width: 100%;
            box-shadow: 0 8px 12px #ebedf0;
            /*padding-bottom:60px;*/
            display: flex;
            flex-direction: column;
            align-items: center;

            .logo {
                width: 80px;
                height: 80px;
                margin-top: 20px;
            }
            .title_3{
                font-size: 14px;
                color: #3D3D3D;
                font-width: 600;
                font-family: "Roboto-Medium";
            }
            .repayment{
                font-size: 20px;
                color: $theme_color_green;
                font-weight: 600;
                padding: 10px 0px;
            }
        }
        .hint_msg{
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 20px;
        }
        .btn{
            width: 100%;
            height: 40px;
            margin-top:20px;
            font-size: 14px;
            font-weight: 600;
            border: 0px;
            color: #3D3D3D;
            font-family: "Roboto-Medium";
            display: flex;
            justify-content: center;
            align-items: center;
            background-image: linear-gradient(to right, #F9C620 , #FD9A20);
            border-radius: 5px;
        }
    }
</style>

<style scoped>

</style>


