/**
 * 区分融360和cashtm appno
 */
import HttpUtil from '@/utils/request'; // 导入http中创建的axios实例

const BaseApi = {
    setAppNo:function (appNo) {
        HttpUtil.defaults.headers['app_no'] = appNo;
    },
    setToken:function (type) {
        const token = localStorage.getItem("token");
        if(token){
            if(type===1){
                HttpUtil.defaults.headers['x-auth-token'] = 'Bearer '+token;
                delete HttpUtil.defaults.headers["x-cert-token"];
            }else if(type===2){
                HttpUtil.defaults.headers['x-cert-token'] = 'Bearer '+token;
                delete HttpUtil.defaults.headers["x-auth-token"];
            }
        }
    }
}

export default BaseApi;
