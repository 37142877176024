import HttpUtil from '@/utils/request'; // 导入http中创建的axios实例
import api from "@/api/api";
import qs from "qs";
import BaseApi from "@/api/base";
const login = {
    // 发送验证码
    sendCode (params,appNo) {
        BaseApi.setAppNo(appNo);
        BaseApi.setToken(1);
        return HttpUtil.post (`${api.sendCode}`,params);
    },
    // 登录
    login (params,appNo) {
        BaseApi.setAppNo(appNo);
        BaseApi.setToken(1);
        return HttpUtil.post(`${api.login}`, params);
    },
    //验证code
    checkCode(params,appNo){
        BaseApi.setAppNo(appNo);
        BaseApi.setToken(1);
        let headers = {
            'Content-Type':'application/x-www-form-urlencoded'
        };
        let data= {response:params}
        return HttpUtil.post(`${api.checkCode}`, qs.stringify(data),{headers});
    },
};

export default login;
