<template>
  <div id="app">
    <div v-if="!network">
      <div class="networkbox">
        <van-image :src="require('./assets/erro_pic_planet.png')" class="network"/>
        <span class="err">{{$t("common.network_err")}}</span>
        <div @click="onRefresh" class="refresh">{{$t("common.refresh")}}</div>
      </div>
    </div>
    <Loading v-show="loading"/>
    <router-view v-if="network"/>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import Loading from "@/components/Loading";
  import Fingerprint2 from 'fingerprintjs2'

  export default {
    name: 'App',
    computed: {
      ...mapState(['network','loading'])
    },
    created(){
      console.log("页面初始化")
      //在页面加载时读取sessionStorage里的状态信息
      console.log(sessionStorage.getItem('store'))
      if (sessionStorage.getItem('store')) {
        this.$store.replaceState(Object.assign({}, this.$store.state, JSON.parse(sessionStorage.getItem('store'))));
      }
      //在页面刷新时将vuex里的信息保存到sessionStorage里
      window.addEventListener('beforeunload', () => {
        sessionStorage.setItem('store', JSON.stringify(this.$store.state));
      });

      //获取浏览器指纹

      Fingerprint2.get(function(components) {
        const values = components.map(function(component,index) {
          if (index === 0) { //把微信浏览器里UA的wifi或4G等网络替换成空,不然切换网络会ID不一样
            return component.value.replace(/\bNetType\/\w+\b/, '')
          }
          return component.value
        })
        // 生成最终id murmur
        const murmur = Fingerprint2.x64hash128(values.join(''), 31);
        console.log('浏览器指纹码：'+murmur );
        localStorage.setItem("androidId",murmur);
      })
    },
    components:{
      Loading,
    },
    methods: {
      // 通过跳转一个空页面再返回的方式来实现刷新当前页面数据的目的
      onRefresh () {
        console.log("刷新")
        location.reload();
      }
    }
  }
</script>

<style lang="scss">
  @import './style/fonts/fonts.css';

  /*remove recaptcha logo*/
  .grecaptcha-badge {
    visibility: hidden;
  }

  .networkbox{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    .network{
      width: 100px;
      height: 80px;
    }
    .err{
      padding: 20px;
      font-family: "Roboto-Regular";
      font-size: 16px;
      color:#666;
    }
    .refresh{
      padding: 10px 30px;
      background: $theme_color;
      border-radius: 10px;
      margin-top: 20px;
      color: #fff;
    }
  }

</style>
