//store的是个配置
const state={
//组件状态,相当于组件中的data
    token:"",
    phone:"",
    loanNo:"",
    custNo:"",
    network:true,
    loading: false,
    repayInfo:{
        custNo:"",
        loanNo :"",
        paymentMethod:"",
        repayDate:"",
        repayInstNum:"",
        transAmt:"",
        isOverdue:false,
        rollOverAble:false,//是否展期
        //展期数据
    }
};
export default state;

