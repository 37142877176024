import store from '../vuex/index';

const Cache = {
    clear(){
        store.commit('clearData');
        localStorage.removeItem("phone");
        localStorage.removeItem("token");
        localStorage.removeItem("custNo");
        localStorage.removeItem("loanNo");
        localStorage.removeItem("appNo");
        // localStorage.removeItem("customerId");
        console.log("清除缓存");
    },
    setAppNo(appNo){
        console.log("保存"+appNo)
        localStorage.setItem("appNo",appNo);
    }
}
export default Cache;
