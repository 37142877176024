<template>
    <div class="page">
       <div class="top">
            <!--头部-->
           <div class="contont">
               <div class="top_title">
                   <van-image class="back" :src="require('@/assets/conmon_iocn_backwhite.png')" width="15" height="20" @click="back"/>
<!--                   <p class="title" v-if="!repay.rollOverAble">{{$t("repayment.title")}}</p>-->
<!--                   <p class="title" v-else>{{$t("repayment.title_3")}}</p>-->
                   <div class="app_name_1">{{$t("app.name")}}</div>
               </div>
                <div class="div_col" style="align-items: center;margin-top: 30px;">
                    <span style="color: #fff;font-size: 16px;">{{$t("repayment.title_2")}}</span>
                    <span class="repayment">
                        {{$t("common.money_icon")}}{{repayInfo.stpRepayment.transAmt}}
                    </span>
                </div>
                <!--展期相关-->
               <div class="div_row" v-if="repay.rollOverAble">
                  <div style="flex: 1;">
                      <div class="div_col item_title">
                          Próxima Cantidad
                      </div>
                      <div class="div_col item_title2">
                          {{$t("common.money_icon")}}{{repay.rollOverDetails.nextLoanRepaymentAmt}}
                      </div>
                  </div>
                   <div style="flex: 1">
                       <div class="div_col item_title">
                           Proxima Fecha
                       </div>
                       <div class="div_col item_title2">
                           {{$moment(repay.rollOverDetails.rollOverRepayDay).format('DD/MM/YYYY')}}
                       </div>
                   </div>
               </div>
           </div>
       </div>
        <div class="contont">
            <!--内容-->
            <div class="div_row" style="align-items: center;justify-content: space-between;width: 90%;margin-top: 20px;">
                <van-image :src="require('@/assets/logo_spei.png')" height="30" width="110"/>
                <span style="font-size: 15px;color: #312D83;font-weight: 600;">{{$t("repayment.bank_title")}}</span>
            </div>
            <div style="font-size: 12px;color: #666;margin-top: 20px;font-family: Roboto-Bold">
                {{$t("repayment.msg_1")}}
            </div>
            <div class="div_col" style="margin-top: 20px;">
                <span style="color: #666;font-size: 14px;" class="copy_msg"> {{$t("repayment.nombre")}}</span>
                <div class="div_row" style="justify-content: space-between">
                    <span style="color: #333;font-size: 14px;">{{repayInfo.stpRepayment.nombre}}</span>
                    <van-image :src="require('@/assets/icon-copy.png')" height="15" width="15" @click="copy(repayInfo.stpRepayment.nombre)"/>
                </div>
            </div>
            <div class="div_col" style="margin-top: 20px;">
                <span style="color: #666;font-size: 14px;" class="copy_msg"> {{$t("repayment.tipo")}}</span>
                <div class="div_row" style="justify-content: space-between">
                    <span style="color: #333;font-size: 14px;">{{repayInfo.stpRepayment.tipoDeCuenta}}</span>
                    <van-image :src="require('@/assets/icon-copy.png')" height="15" width="15" @click="copy(repayInfo.stpRepayment.tipoDeCuenta)"/>
                </div>
            </div>
            <div class="div_col" style="margin-top: 20px;">
                <span style="color: #666;font-size: 14px;" class="copy_msg"> {{$t("repayment.clabe")}}</span>
                <div class="div_row" style="justify-content: space-between">
                    <span style="color: #333;font-size: 14px;">{{repayInfo.stpRepayment.clabeNo}}</span>
                    <van-image :src="require('@/assets/icon-copy.png')" height="15" width="15" @click="copyClabeNo(repayInfo.stpRepayment.clabeNo)"/>
                </div>
            </div>
            <div class="div_col" style="margin-top: 20px;">
                <span style="color: #666;font-size: 14px;" class="copy_msg"> {{$t("repayment.stp")}}</span>
                <div class="div_row" style="justify-content: space-between">
                    <span style="color: #333;font-size: 14px;">{{repayInfo.paymentMethod}}</span>
                    <van-image :src="require('@/assets/icon-copy.png')" height="15" width="15" @click="copy(repayInfo.paymentMethod)"/>
                </div>
            </div>
            <div class="div_col" style="margin-top: 20px;">
                <span style="color: #666;font-size: 14px;" class="copy_msg"> {{$t("repayment.concepto")}}</span>
                <div class="div_row" style="justify-content: space-between">
                    <span style="color: #333;font-size: 14px;">{{repayInfo.stpRepayment.concepto}}</span>
                    <van-image :src="require('@/assets/icon-copy.png')" height="15" width="15" @click="copy(repayInfo.stpRepayment.concepto)"/>
                </div>
            </div>

            <div class="div_row" style="margin-top: 20px;color: #999;font-size: 12px;">
                {{$t("repayment.msg_3")}}
            </div>
        </div>

        <van-overlay :show="show">
            <div class="wrapper">
                <div class="block">
                    <div class="title"> {{$t("diglog.title")}}</div>
                    <div class="msg"> {{$t("diglog.msg")}}</div>
                    <div :class="isEnable?'btn_enable':'btn_unenable'"  @click="closeDialog">
                        <template v-if="isEnable">
                            {{$t("diglog.btn")}}
                        </template>
                        <template v-else>
                            <van-count-down
                                    :time="time"
                                    :auto-start="true"
                                    @finish="timeFinish"
                                    @change="timeChange">

                                <template #default="timeData">
                                    {{ timeData.seconds }}S
                                </template>
                            </van-count-down>
                        </template>
                    </div>
                </div>
            </div>
        </van-overlay>
    </div>
</template>

<script>
    import API from "@/api";
    import {pagePoint} from "@/utils/PagePoint";
    const EVENT_ID = "h5_repay_by_STP";
    const EVENT_ID_WINDOW = "h5_repay_by_STP_confirm_window";
    export default {
        name: "BankRepayment",
        data(){
          return{
              show:false,
              isEnable:false,
              isExtension:false,
              time:5000,
              btnMessage:"5S",
              repay:{
                  custNo:"",
                  loanNo :"",
                  paymentMethod:"STP",
                  repayDate:"",
                  repayInstNum:"",
                  transAmt:"",
                  isOverdue:false,//是否逾期
                  rollOverAble:false,//是否展期
                  //展期数据
                  rollOverDetails:{
                      rollOverAmt:"",
                      rollOverDay:"",
                      rollOverRepayDay:"",
                  },
                  nextLoanRepaymentAmt:""
              },
              repayInfo:{
                  paymentMethod: "",//paymentMethod
                  stpRepayment: {
                      nombre: "",//收款账户名 ,
                      tipoDeCuenta: "",// 账户类型 ,
                      destinatario: "",//收款银行
                      concepto: "",//备注
                      clabeNo: "",// CLABE
                      transAmt: ""//交易金额
                  },
                  conektaRepayment: {
                      concepto:"" ,// 备注
                      orderNo:"" ,//还款编码
                      referenceNo:"" ,//还款编码
                      transAmt:""//交易金额
                  }
              }
          }
        },
        mounted(){
            let data = this.$store.state.repayInfo;
            console.log("读取的数据")
            console.log(data)
            if(data){
                this.repay = data;
                console.log("repayment:")
                console.log(this.repay.transAmt);
                if(this.repay.rollOverAble){
                    this.show = true;
                    this.isExtension = true;
                    pagePoint(EVENT_ID_WINDOW,"enter_the_page","in",(new Date()).valueOf());
                    //判断是否逾期
                    if(this.repay.isOverdue){
                        pagePoint(EVENT_ID,"enter_the_page","page_status","overdue_with_extension");
                    }else{
                        pagePoint(EVENT_ID,"enter_the_page","page_status","normal_with_extension");
                    }
                }else{
                    //判断是否逾期
                    if(this.repay.isOverdue){
                        pagePoint(EVENT_ID,"enter_the_page","page_status","overdue_no_extension");
                    }else{
                        pagePoint(EVENT_ID,"enter_the_page","page_status","normal_no_extension");
                    }
                }
                //进入页面埋点
                pagePoint(EVENT_ID,"enter_the_page","in",(new Date()).valueOf());
                this.repayment();
            }
        },
        methods:{
            back(){
                this.$router.go(-1);
            },
            repayment(){
                let parms = {
                    "advance": "10000000",//是否提前结清 码值1000 ,
                    "custNo": this.repay.custNo,
                    "defer": this.repay.rollOverAble,//是否展期
                    "loanNo": this.repay.loanNo,
                    "paymentMethod": this.repay.paymentMethod.toUpperCase(),//还款方式 CONEKTA
                    "repayDate": this.repay.repayDate,//还款日期
                    "repayInstNum":  this.repay.repayInstNum,//还款期数
                    "tranAppType": "Android",
                    "transAmt":  this.repay.rollOverAble?this.repay.rollOverDetails.rollOverAmt:this.repay.transAmt//还款金额
                };
                let that = this;
                API.lanaplus.repayment(parms).then((res)=>{
                    if(res&&res.data){
                        that.repayInfo = res.data;
                    }else{
                        //说明数据为null异常
                        // that.$router.replace({path: '/dataerror'});
                    }
                }).catch(error=>{
                    console.log(error)
                })
            },
            timeFinish(){
                this.isEnable = true;
            },
            timeChange(timeData){
                if(timeData&&timeData.seconds<=0){
                    this.isEnable = true;
                }
            },
            closeDialog(){
                if(this.isEnable){
                    console.log("关闭")
                    this.show = false;
                    pagePoint(EVENT_ID_WINDOW,"click_confirm","in",(new Date()).valueOf());
                }
            },
            //生成链接到pc发布
            copy(msg) {
                //创建一个input框
                const input = document.createElement("input");
                //将指定的DOM节点添加到body的末尾
                document.body.appendChild(input);
                //设置input框的value值为直播地址
                input.setAttribute("value", msg);
                //选取文本域中的内容
                input.select();
                //copy的意思是拷贝当前选中内容到剪贴板
                //document.execCommand（）方法操纵可编辑内容区域的元素
                //返回值为一个Boolean，如果是 false 则表示操作不被支持或未被启用
                if (document.execCommand("copy")) {
                    document.execCommand("copy");
                    this.$toast("Copied to clipboard");
                }
                //删除这个节点
                document.body.removeChild(input);
            },
            copyClabeNo(msg){
                this.copy(msg);
                pagePoint(EVENT_ID,"copy_CLABEnumber","click",(new Date()).valueOf());
            }
        }
    }
</script>

<style scoped lang="scss">
    .item_title{
        align-items: center;
        color: #fff;
        font-size:12px;
        font-family: "Roboto-Medium"
    }
    .item_title2{
        align-items: center;
        color: #fff;
        font-size:20px;
        font-weight: 600;
        font-family: "Roboto-Medium"
    }
    .div_row{
        display: flex;
        flex-direction: row;
        width: 100%;
        font-font: Roboto-Medium;
    }
    .app_name_1{
        font-size: 21px;
        font-weight: 600;
        color: #fff;
        font-family: 'Roboto-Medium';
        letter-spacing: 2px;
        align-self: center;
        width: 100%;
        text-align: center;
    }
    .div_col{
        display: flex;
        flex-direction: column;
        width: 100%;
        font-font: Roboto-Medium;
    }
    .page{
        width: 100vw;
        height: 100vh;
        background: #ffffff;
        font-family: "Roboto-Medium";
        .top{
            background: $theme_color_green;
            width: 100%;
            display: flex;
            flex-direction: column;
            .contont{
                width: 90%;
                margin-left: 5%;
                display: flex;
                flex-direction: column;
                margin-bottom: 20px;
                .top_title{
                    width: 100%;
                    height: 50px;
                    display: flex;
                    flex-direction: row;
                    .back{
                       align-self: start;
                        margin-top: 15px;
                    }
                }
                .repayment{
                    font-size: 36px;
                    color: #fff;
                    font-weight: 600;
                    padding: 10px 0px;
                }
            }

        }
        .contont{
            width: 90%;
            margin-left: 5%;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }

    .wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }

    .block {
        width: 70vw;
        padding: 20px;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        .title{
            font-size: 16px;
            color: #666;
            font-family: "Roboto-Medium";
        }
        .msg{
            width: 90%;
            font-size: 12px;
            color: #999;
            font-family: "Roboto-Medium";
            line-height: 16px;
            margin-top: 20px;
            padding-left: 20px;
            padding-right: 20px;
            text-align: left;
        }
        .btn_enable{
            background: #FAD21E;
            height: 40px;
            padding-left: 20px;
            padding-right: 20px;
            font-size: 14px;
            font-width: 600;
            color: #333;
            min-width: 30vw;
            font-family: "Roboto-Medium";
            margin-top: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 5px;
        }
        .btn_unenable{
            background: #E5E5E5;
            height: 40px;
            padding-left: 20px;
            padding-right: 20px;
            font-size: 14px;
            font-width: 600;
            color: #3D3D3D;
            min-width: 30vw;
            font-family: "Roboto-Medium";
            margin-top: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 5px;
        }
    }
</style>
