const mutations = {
//包含多个更新state的对象,例如
    saveToken(state,token){
        state.token = token;
    },
    savePhone(state,phone){
        state.phone = phone;
    },
    saveLoanNo(state,locanNo){
        state.loanNo = locanNo;
    },
    saveCustNo(state,custNo){
        state.custNo = custNo;
    },
    showLoading(state){
        state.loading = true
    },
    hideLoading (state) {
        state.loading = false
    },
    changeNetwork(state,status){
        state.network = status
    },
    //保存还款数据
    saveRepayInfo(state,repayInfo){
        state.repayInfo = repayInfo;
    },
    clearData(state){
        state.token = "";
        state.phone = "";
        state.loanNo = "";
        state.custNo = "";
    }
};
export default mutations;
