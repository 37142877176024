//页面埋点
import axios from 'axios';
import AppConfig from "@/config/appconfig";
import i18n from "@/i18n";
//埋点测试url
// var POINT_SERVICE = "https://test-action.quantx.mx/";
var POINT_SERVICE = "https://action.quantx.mx/";

// 创建axios实例
var instance = axios.create({timeout: 1000 * 60});
// 设置post请求头
instance.defaults.headers['Content-Type'] = 'application/json';
//设置请求头参数
// instance.defaults.headers['X-App-Name'] = AppConfig.appName;
// instance.defaults.headers['X-App-No'] = AppConfig.appNo;
// instance.defaults.headers['X-App-Version'] = AppConfig.versionNo;
// instance.defaults.headers['X-App-Type'] = AppConfig.appType;
// instance.defaults.headers['X-User-Language'] =i18n.locale;

//上传埋点 session
export function basePoint(data) {
    new Promise((resolve,reject)=>{
        instance.post(POINT_SERVICE+"api/third/track/base",data)
            .then(res=>{
                resolve(res.data);
            }).catch(err=>{
            console.log("埋点失败",err)
            reject(err.data);
        })
    });
}

//操作埋点
export function pagePoint(eventId,label,key,value) {
    new Promise((resolve,reject)=>{
        let data = {
            androidId: localStorage.getItem("androidId"),
            custNo:localStorage.getItem("custNo"),
            loanNo:localStorage.getItem("loanNo"),
            phoneNo:localStorage.getItem("phone"),
            enventList:[
                {
                    eventId:eventId,
                    innerNetworkIp:"",
                    networkType:"",
                    key:key,
                    label:label,
                    recordTime:(new Date()).valueOf(),
                    timeOnline:"20210207",
                    value:value
                }
            ]
        }
        instance.post(POINT_SERVICE+"/api/third/track/detail",JSON.stringify(data))
            .then(res=>{
                resolve(res.data);
            }).catch(err=>{
            console.log("埋点失败",err)
            reject(err.data);
        })
    });
}
