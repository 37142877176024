import Vue from 'vue'
import App from './App.vue'
import router from './router' // 导入路由文件
import store from './vuex' // 导入vuex文件
import Vant from 'vant';
import 'vant/lib/index.css';
import i18n from './i18n';
import moment from 'moment'



Vue.config.productionTip = false
Vue.prototype.$moment = moment;


//解决重复路由报错
// const originalPush = Router.prototype.push;
// Router.prototype.push = function push (location) {
//   return originalPush.call(this, location).catch(err => err)
// }
Vue.use(Vant);
new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')

