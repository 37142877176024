import Vue from 'vue'
import Vuex from 'vuex'
import state from "./state"
import mutations from "./mutations"
import actions from "./actions"
import getter from "./getter"

Vue.use(Vuex);

//暴露store
const store = new Vuex.Store({
    state:state,
    mutations:mutations,
    actions:actions,
    getter:getter,
})

export default store
