<template>
    <div class="box">
        <van-row class="app_logo">
            <van-image :src="require('../../assets/logo.png')" class="logo"/>
        </van-row>
        <div class="app_contont">
            <span class="title">{{$t("sign.sign_title")}}</span>

            <div class="name">
                <span style="color: #9A9A9A">{{$t("sign.sign_name")}}</span>
                <div style="margin-top: 5px;display: flex;flex-direction: row;width: 60%;justify-content: center">
                    <span>{{fatherLastName}}</span>
                    <span style="margin-left: 5px;">{{motherLastName}}</span>
                    <span  style="margin-left: 5px;">{{custName}}</span>
                </div>
            </div>
            <div class="name">
                <span style="color: #9A9A9A">{{$t("sign.sign_phone")}}</span>
                <span style="margin-top: 5px;">{{phone}}</span>
            </div>
            <van-checkbox v-model="radio" checked-color="#21AE5B" class="agreement" @change="changeCheckBox">
                <span class="agreement_msg">{{$t("sign.sign_agreement")}}</span>
            </van-checkbox>

            <van-button class="btn" @click="goHome">
                {{$t("sign.sign_btn")}}
            </van-button>
        </div>

        <div class="service_box">
            <div class="service">
                <span>{{$t("common.service_phone_title")}}</span>
                <span class="phone">{{$t("common.service_phone")}}</span>
            </div>
        </div>
    </div>
</template>

<script>
    import API from "@/api";
    import {Toast} from "vant";
    import AppConfig from "@/config/appconfig";
    import Cache from "@/utils/ClearCache";
    import {basePoint,pagePoint} from "@/utils/PagePoint";
    const EVENT_ID = "h5_loan_extension_confirm_and_continue";
    export default {
        name: "Login",
        data(){
            return{
                name:"******",
                phone:"******",
                motherLastName:"***",
                fatherLastName:"***",
                custName:"***",
                radio:"1",
                custNo: "",
                loanNo: "",
                token:"",
                userPhone:"",//真实的手机号
                sitekey:AppConfig.codeKey,

            }
        },
        created() {
            pagePoint(EVENT_ID,"enter_the_page","in",(new Date()).valueOf());
        },
        mounted(){
            //清除缓存
            Cache.clear();
            Cache.setAppNo(AppConfig.appNo);
            //获取url中id
            let id = this.$route.query.customerId;
            if(id&&id!=="undefined"){
                //查询数据
                localStorage.setItem("customerId",id);
                this.getInfo(id);
            }else{
                //从本地获取
                id = localStorage.getItem("customerId");
                if(id&&id!=="undefined"){
                    this.getInfo(id);
                }
            }

        },
        methods:{
            getInfo(id){
                console.log("customerId:"+id);
                let data = {
                    "customerId":id
                };
                let that = this;
                API.lanaplus.getUserInfo(data).then(res=>{
                    console.log(res);
                    if(res&&res.data){
                        let data = res.data;
                        // that.name = that.replaceStr(data.custName,"****",false);
                        that.phone = that.replaceStr(data.phone,"****",true);
                        //用户名
                        that.fatherLastName = that.replaceNameStr(data.fatherLastName);
                        that.motherLastName =  that.replaceNameStr(data.motherLastName);
                        that.custName =  that.replaceNameStr(data.name);
                        that.name =  that.replaceNameStr(data.name);
                        that.userPhone = data.phone;
                        that.uploadBasePoint(data);
                        let token = data.token;
                        if(token&&token.length>0){
                            localStorage.setItem("token",data.token);
                            localStorage.setItem("phone",data.phone);
                            that.$store.dispatch("saveToken",data.token);
                            that.$store.dispatch("savePhone",data.phone);
                            localStorage.setItem("loanNo",data.loanNo);
                            that.$store.dispatch("saveLoanNo",data.loanNo);
                            localStorage.setItem("custNo",data.custNo);
                            that.$store.dispatch("saveCustNo",data.custNo);
                            that.loanNo = data.loanNo;
                            that.custNo = data.custNo;
                            that.token = data.token;
                        }else{
                            that.$toast(that.$t('common.error'));
                        }
                    }
                }).catch(res=>{
                    console.log(res);
                    that.$toast(that.$t('common.error'));
                })
            },
            goHome(){
                //判断是否有数据
                if(this.phone&&this.custNo&&this.token){
                    pagePoint(EVENT_ID,"click_confirm_and_continue","click",(new Date()).valueOf());
                    this.$router.push({path: '/extension/home'});
                }else{
                    this.$toast("User information does not exist");
                    pagePoint(EVENT_ID,"click_confirm_and_continue","error","User information does not exist");
                }

            },
            replaceStr(str,changeStr,isPhone){
                let index = 0;
                if(str){
                    if(isPhone){
                        return str.substr(0,3)+changeStr+str.substr(str.length-3, str.length-1);
                    }else{
                        if(str.length>=4){
                            let names = str.split(" ");
                            index = names[0].length;
                        }
                        return str.substr(0, index) + changeStr;
                    }
                }
                return "****";
            },
            replaceNameStr(str){
                if(str&&str.length>3){
                   return str.substr(0,3) + "***";
                }
                return str;
            },
            showToast(msg){
                Toast({
                    message: msg,
                    duration: 2000,
                    forbidClick: true
                });
            },
            changeCheckBox(){
                console.log(this.radio)
                if(this.radio){
                    pagePoint(EVENT_ID,"click_agreement","click",(new Date()).valueOf());
                }else{
                    pagePoint(EVENT_ID,"cancel_agreement","click",(new Date()).valueOf());
                }

            },
            //埋点base接口
            uploadBasePoint(res){
                let data = {
                    "androidId":localStorage.getItem("androidId"),
                    "appNo":AppConfig.appNo,
                    "phoneNo":res.phone,
                    "custNo":res.custNo,
                    "loanNo":res.loanNo,
                    "recordTs":(new Date()).valueOf(),
                }
                basePoint(JSON.stringify(data));
            }
        }
    }
</script>

<style lang="scss" scoped>
    .box{
        height: 100vh;
        width: 100vw;
        background: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        .app_logo{
            width: 100vw;
            height: 25vh;
            margin-top: 5vh;
            display: flex;
            align-items: center;
            justify-content: center;
            .logo{
                width:25vh;
                height: 25vh;
            }
        }
        .app_contont{
            width: 90vw;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 10px;
        }
        .title{
            font-size: 16px;
            color: #bfbfbf;
            font-family: "Roboto-Regular";
            padding-bottom: 10px;
            width: 100%;
            text-align: center;
        }
        .name{
            font-size: 16px;
            font-width: 500;
            color: #666;
            font-family: "Roboto-Regular";
            padding-top: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
        }
        .service_phone{
            line-height: 20px;
            margin-top: 50px;
            padding: 0px 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            font-size: 16px;
            font-width: 500;
            color: #666;
        }
        .agreement{
            align-items: flex-start !important;
            margin-top: 40px;
        }
        .agreement_msg{
            font-size: 14px;
            font-width: 600 !important;
            color: #999999 !important;
        }
        .btn{
            width: 90%;
            height: 50px;
            margin-top:20px;
            font-size: 16px;
            font-family: "Roboto-Regular";
            font-weight: 600;
            border: 0px;
            color: #333;
            display: flex;
            justify-content: center;
            align-items: center;
            background-image: linear-gradient(to right, #F9C620 , #FD9A20);
            border-radius: 5px;
        }
    }


    .service_box{
        width: 100vw;
        text-align: center;
        font-size: 12px;
        font-family: "Roboto-Medium";
        color: #999999;
        width: 100%;
        display: flex;
        margin-top: 20px;
        justify-content: center;
        align-items: center;
        /*position: absolute;*/
        /*bottom: 40px;*/
    }
    .service{
        width: 100vw;
        text-align: center;
        font-size: 12px;
        font-family: "Roboto-Medium";
        color: #999999;
        .phone{
            color: $theme_color_green;
            margin-left: 5px;
        }
    }

</style>
