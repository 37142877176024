import login from '@/api/login';
import repayment from "@/api/repayment";
import noLogin from "@/api/nologin";
import lanaplus from "@/api/lanaplus";
const API = {
    login:login,
    repayment:repayment,
    noLogin:noLogin,
    lanaplus:lanaplus
}
export default API;
