<template>
    <div class="loading">
        <van-loading size="60px" type="spinner" vertical class="bg">
            <span style="color: #fff;font-size: 18px;">{{$t('common.loading')}}</span>
        </van-loading>
    </div>
</template>

<script>
    export default {
        name: "Loading"
    }
</script>

<style lang="scss" scoped>
    .loading{
        position: fixed;
        top:0;
        left:0;
        z-index:121;
        width: 100vw;
        height: 100vh;
        background: rgba(0,0,0,0.3);
        /*display: table-cell;*/
        /*vertical-align: middle;*/
        /*text-align: center;*/
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .bg{
        /*position: fixed;*/
        /*top:48vh;*/
        /*left:48vw;*/
        color: #fff;
    }
</style>
