<template>
    <div class="box">
        <span class="title">Sin deuda pendiente</span>
        <van-image class="icon" :src="require('@/assets/home_funddisbursed_icon.png')" width="100" height="100"/>
        <span class="msg">Obtenga LanaPlus en Google Play</span>
        <van-image class="googleplay" :src="require('@/assets/google.png')" width="200" height="50" @click="goGoolePlay()"/>
    </div>
</template>

<!--没有还款的数据-->
<script>
    export default {
        name: "NoRepay",
        methods:{
            goGoolePlay(){
                window.location.href = "https://play.google.com/store/apps/details?id=com.lanaplus.mx";
            }
        }
    }
</script>

<style scoped>
    .box{
        width: 100%;
        background: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 20px;
    }
    .title{
        line-height: 50px;
        font-family: Roboto-Medium;
        font-size: 16px;
        color: #666666;
        margin-top: 20px;
    }
    .msg{
        line-height: 50px;
        font-family: Roboto-Medium;
        font-size: 14px;
        color: #999999;
    }
    .icon{

    }
    .googleplay{
        margin-top: 10px;
    }
</style>
