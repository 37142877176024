<template>
    <div class="box">
        <van-image :src="require('../assets/erro_pic_404.png')" class="logo"/>
        <span class="error_404">
            {{$t("common.error_404")}}
        </span>
        <div class="msg">
            <span> {{$t("common.error_msg")}}</span>
            <span style="color:#F9C01F"> {{$t("common.service_phone")}}</span>
        </div>
    </div>

</template>

<script>
    export default {
        name: "Error"
    }
</script>

<style scoped>
    .box{
        height: 100vh;
        width: 100vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .error_404{
        color: #666666;
        font-family: "Roboto-Medium";
        font-size: 18px;
        font-width: 600;
        padding: 10px;
    }
    .logo{
        height: 75px;
        width: 75px;
    }
    .msg{
        color: #999;
        font-family: "Roboto-Medium";
        font-size: 15px;
        width: 80vw;
        text-align: center;
    }
</style>
