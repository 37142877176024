<template>
    <div style="width: 98vw;height:100vh;display: flex;flex-direction:column;justify-content: center;align-items: center;">
        <label style="margin: 20px;font-size: 20px;color: #666;">客户加密ID查询</label>
        <van-form  style="width: 90vw;">
            <van-field
                    class="item"
                    v-model="custNo"
                    name="custNo"
                    placeholder="客户编号"
                    :rules="[{ required: true, message: '请填写客户编号' }]"
            />
            <div style="margin: 16px;">
                <van-button round block type="info" @click="getCustId">提交</van-button>
            </div>
        </van-form>
        <div v-if="msg!==''" style="width: 80%;display: flex;flex-direction: column;justify-content: center;align-items: center;">
            <label style="margin: 10px;font-size: 20px;color: #666;">查询的CustomerId:</label>
            <label style="margin: 20px;font-size: 16px;color: #666;width: 80%;word-wrap:break-word;text-align: center;">{{msg}}</label>
            <van-button round block type="info" @click="goLogin">跳转登录</van-button>
        </div>

    </div>
</template>

<script>
    import API from "@/api";
    export default {
        name: "Test",
        data() {
            return {
                custNo: '',
                msg:""
            };
        },
        methods: {
            getCustId() {
               if(this.custNo){
                   API.lanaplus.test(this.custNo).then((res)=>{
                       this.msg = res.data;
                   })
               }
            },
            goLogin(){
                // window.location.href = "/extension?customerId="+this.msg;
                this.$router.replace({path: '/extension',query:{customerId:this.msg}});
            }
        },
    }
</script>

<style scoped>
    .item{
        width: 100%;
        border: 1px #bfbfbf solid;
        border-radius: 5px;
    }
</style>
