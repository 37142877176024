import Vue from 'vue'
import Router from 'vue-router'
import NP_Login from "@/views/normal_ postpone/Login";
import NP_Home from "@/views/normal_ postpone/Home";
import BankRepayment from "@/views/normal_ postpone/BankRepayment";
import OxxoRepayment from "@/views/normal_ postpone/OxxoRepayment";
//cashtm 正常+展期还款
Vue.use(Router);


let routes = [
    {
        path: '/extension',
        name: 'NP_Login',
        component: NP_Login,
    },
    {
        path:"/extension/home",
        name:"NP_Home",
        component:NP_Home,
    },
    {
        path:"/bankrepayment",
        name:"BankRepayment",
        component:BankRepayment
    },
    {
        path:"/oxxoRepayment",
        name:"OxxoRepayment",
        component:OxxoRepayment
    }
];

export default routes;
