<template>
    <div class="box">
        <div class="overdu_box">
            <span v-if="loanInfo.loanInfoData.overdueStatus">
                 Atrasado
            </span>

        </div>
        <div :class="[!loanInfo.loanInfoData.overdueStatus?'normal_bg':'overdue_bg','box_top']">
            <span class="top_title">{{$t("no_extension.adeudado")}}</span>
            <span class="top_amount">
                {{$t("common.money_icon")}}{{loanInfo.loanInfoData.feeDetail!=null?loanInfo.loanInfoData.feeDetail.repayment:""}}
            </span>
            <span class="top_msg">{{$t("no_extension.repayment_date_msg")}}</span>
            <span class="top_data">
                {{loanInfo.loanInfoData.feeDetail!=null?$moment(loanInfo.loanInfoData.loanDateInfo.currentRepayDate).format('DD/MM/YYYY'):""}}
            </span>
        </div>
        <div class="items" v-if="loanInfo.loanInfoData.feeDetail!=null&&loanInfo.loanInfoData.feeDetail.fees!=null">
            <div class="item">
                <div class="left_title">Cantidad del Préstamo</div>
                <div class="right_data">{{$t("common.money_icon")}}{{loanInfo.loanInfoData.feeDetail.loanAmount}}</div>
            </div>
            <div class="item" v-for="item in loanInfo.loanInfoData.feeDetail.fees" v-bind:key="item.displayOrder">
                <div class="left_title">{{item.displayName}}</div>
                <div class="right_data">{{$t("common.money_icon")}}{{item.displayValue}}</div>
            </div>
            <div class="item" v-if="loanInfo.loanInfoData.feeDetail.overDueAmt>0">
                <div class="overdue">Monto de pago tardío</div>
                <div class="overdue">{{$t("common.money_icon")}}{{loanInfo.loanInfoData.feeDetail.overDueAmt}}</div>
            </div>
        </div>
        <div class="btn1" @click="openBankDialog(1)">
            {{$t("no_extension.repayment_btn_msg")}}
            {{loanInfo.loanInfoData.feeDetail!=null?loanInfo.loanInfoData.feeDetail.repayment:""}}
        </div>
        <div class="view"></div>
        <div class="box_card">
            <span class="title"> {{$t("extension.card_title")}}</span>
            <!---->
            <div class="box_repayment">
                <div class="left_item">
                    <div>Próxima Cantidad</div>
                    <div style="font-weight: 600">
                        {{$t("common.money_icon")}}
                        {{loanInfo.rollOverDetails!=null?loanInfo.rollOverDetails.nextLoanRepaymentAmt:""}}
                    </div>
                </div>
                <div class="right_item">
                    <div>Próxima Fecha</div>
                    <div style="font-weight: 600">
                        {{loanInfo.rollOverDetails!=null? $moment(loanInfo.rollOverDetails.rollOverRepayDay).format('DD/MM/YYYY'):""}}
                    </div>
                </div>
            </div>

            <div class="btn2" @click="openBankDialog(2)">
                {{$t("extension.btn1")}}
                {{loanInfo.rollOverDetails!=null?loanInfo.rollOverDetails.rollOverAmt:""}}
                {{$t("extension.btn2")}}
            </div>

            <div class="msg" v-if="loanInfo.loanInfoData.overdueStatus">
                {{$t("extension.overdue_msg")}}
            </div>
        </div>

        <van-popup v-model="showBank" position="bottom" :style="{ height: '40%' }">
            <BankPopup :repay-info="repayInfo" :roll-over-able="rollOverAble"/>
        </van-popup>
    </div>
</template>

<!--有展期 正常和逾期-->
<script>
    import BankPopup from "@/views/normal_ postpone/components/BankPopup";
    import {pagePoint} from "@/utils/PagePoint";
    const EVENT_ID = "h5_loan_repay";
    export default {
        name: "Extension",
        props:{
            loanInfo:{
                type:Object,
                default(){
                    return {
                        "loanStat": "",//REPAYMENT才显示按钮
                        "certStatus": null,//暂时没用
                        "loanInfoData": {
                            "loanNo": "",
                            "custNo": "",
                            "instNum": "",
                            "prodNo": "",
                            "bankNo": "",
                            "bankCode": "",
                            "bankOrgName": "",
                            "overdueStatus": false,
                            "loanDateInfo": {
                                "loanDays": 1,
                                "currentInstNum": 1,
                                "currentRepayDate": ""
                            },
                            "feeDetail": {
                                "loanNo": "",
                                "loanAmount": "",
                                "payment": "",
                                "repayment": "",
                                "overDueAmt": null,
                                "fees": [{
                                    "displayOrder": 0,
                                    "displayName": "22",
                                    "value": 0,
                                    "displayValue": 5
                                }]
                            },
                            "reapplyDate": null
                        },
                        "rollOverDetails": {
                            "rollOverAmt":"",
                            "rollOverDay":"",
                            "rollOverRepayDay":"",
                            "nextLoanRepaymentAmt":"",
                            "detailList":[
                                {
                                    "originalValue":"",
                                    "value":"",
                                    "displayName":"",
                                    "percent":"",
                                    "fixedVal":"",
                                }
                            ]
                        },//展期数据
                        "rollOverAble":false,//是否展期
                    }
                }
            },
            repayInfo:{
                type:Object,
                default() {
                    return {//还款数据
                        custNo:"",
                        loanNo :"",
                        paymentMethod:"STP",
                        repayDate:"",
                        repayInstNum:"",
                        transAmt:"",
                        isOverdue:false,//是否逾期
                        rollOverAble:false,//是否展期
                        //展期数据
                        rollOverDetails:{
                            rollOverAmt:"",
                            rollOverDay:"",
                            rollOverRepayDay:"",
                        },
                        //下一次展期还款数据
                        nextLoanRepaymentAmt:""
                    };
                }
            }
        },
        components:{
            BankPopup
        },
        data(){
            return{
                showBank:false,
                rollOverAble:false,
            }
        },
        methods:{
            openBankDialog(index){
                this.showBank = true;
                if(index===1){
                    this.rollOverAble = false;
                    pagePoint(EVENT_ID,"click_full_amount_repay","click",(new Date()).valueOf());
                }else{
                    this.rollOverAble = true;
                    pagePoint(EVENT_ID,"click_extension_repay","click",(new Date()).valueOf());
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .box{
        width: 100%;
        background: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        .overdu_box{
            height: 60px;
            background: white;
            display: flex;
            justify-content: center;
            align-items: center;
            color:  #DE4747;
            font-size: 18px;
            font-family: Roboto-Medium;
        }
        .box_top{
            height: 200px;
            width: 200px;
            background: $theme_color_green;
            border-radius: 100px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: white;
            .top_title{
                font-size: 18px;
                font-family: Roboto-Medium;
            }
            .top_amount{
                font-size: 32px;
                font-weight: 600;
                font-family: Roboto;
            }
            .top_msg{
                font-size: 8px;
                font-family: Roboto-Medium;
                margin-top: 3px;
            }
            .top_data{
                font-size: 19px;
                font-family: Roboto;
                margin-top: 3px;
            }
        }

        .items{
            width: 90%;
            display: flex;
            flex-direction: column;
            margin-top: 20px;
            .item{
                width: 100%;
                margin-top: 3px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                font-family: Roboto-Medium;
                font-size: 14px;
                .left_title{
                    color: #999999;
                }
                .right_data{
                    color: #666666;
                }
            }
        }
        .btn1{
            width: 80%;
            border-radius: 5px;
            font-family: Roboto-Medium;
            font-size: 18px;
            font-weight: 600;
            height: 45px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 25px;
            background: white;
            border: 1px #B3B3B3 solid;
            color: #808080;
            margin-bottom: 20px;
        }

        .btn2{
            width: 80%;
            border-radius: 5px;
            font-family: Roboto-Medium;
            background: #FAD21E;
            font-size: 18px;
            font-weight: 600;
            height: 45px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 25px;
        }

        .normal{
            color: $theme_color_green;
        }
        .normal_bg{
            background: $theme_color_green;
        }
        .overdue{
            color: #DE4747;
        }
        .overdue_bg{
            background: #DE4747;
        }
        .btn_normal_bg{
            background: #FAD21E;
            color: #333333;
        }
        .btn_overdue_bg{
            background:#DE4747;
            color: white;
        }
        .view{
            height: 15px;
            background: #F6F7F7;
            width: 100%;
        }
        .box_repayment{
            width: 100%;
            display: flex;
            flex-direction: row;
            color: #999999;
            font-family: Roboto-Medium;
            font-size: 14px;
            .left_item{
                flex: 1;
                display: flex;
                flex-direction: column;
                align-items: center;
            }
            .right_item{
                flex: 1;
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }
        .box_card{
            width: 100%;
            background: white;
            box-shadow: 0 8px 12px #ebedf0;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-bottom: 20px;
            .title{
                font-size: 16px;
                font-weight: 600;
                font-family: Roboto;
                line-height: 50px;
            }
            .msg{
                width: 90%;
                color: #B3B3B3;
                font-size: 12px;
                font-family: Roboto-Medium;
                line-height: 16px;
                margin-top: 10px;
            }
        }
    }

</style>
