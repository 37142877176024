const actions = {
//包含多个对应事件的回调函数
    saveToken(context,token){
        context.commit("saveToken",token);
    },
    savePhone(context,phone){
        context.commit("savePhone",phone);
    },
    saveLoanNo(context,loanNo){
        context.commit("saveLoanNo",loanNo);
    },
    saveCustNo(context,custNo){
        context.commit("saveCustNo",custNo);
    },
    //保存还款数据
    saveRepayInfo(context,repayInfo){
        context.commit("saveRepayInfo",repayInfo);
    },
};
export default actions;
