import Vue from 'vue';
import Router from 'vue-router';
import Refresh from "@/components/Refresh";
//正常还款+展期还款

import Error from "@/components/Error";
import DataError from "@/components/DataError";
import Test from "@/components/Test"
import NormalPostpone from "./normal_postpone"
//正常还款


// 配置
Vue.use(Router);

const router = new Router({
    // mode: 'history',//去掉#号 生产包去掉
    routes: [
        {
            path: '/refresh',
            name: 'Refresh',
            component: Refresh,
        },
        {
            path: '/error',
            name: 'Error',
            component: Error,
        },
        {
            path: '/test',
            name: 'Test',
            component: Test,
        },
        {
            path: '/dataerror',
            name: 'DataError',
            component: DataError,
        },
        ...NormalPostpone,
    ],
});



router.beforeEach((to, from, next) => {
    if (to.matched.length === 0) {
        from.name ? next({
            name: from.name
        }) : next('/error');
    } else {
        next(); //如果匹配到正确跳转
    }
});

export default router;

