const AppConfig = {
    appNo:"201",
    appName:"lanaplus",
    versionNo:"2.0.0",
    appType:"10090003",
    normalState:"10270002",
    loginType:"10470001",//快捷登录
    overdue:"10270003",
    googleUrl:"https://cashtm.app.link/KWxL08IJsab",
    // codeKey:"6LegFdcZAAAAAAu1ptRxE-APiyW9ZvOwWTEb5-SS",//测试
    codeKey:"6LeU8q4ZAAAAADj2SRVmBRoQorC098ieJEerhvXE",//生产
    helpUrl:"https://www.cashtm.in/faq/faq.html"
};
export default AppConfig;
