import HttpUtil from '@/utils/request'; // 导入http中创建的axios实例
import api from "@/api/api";
import qs from "qs";
import BaseApi from "@/api/base";

const noLogin = {
    //免登录还款
    signInFree(params,appNo){
        BaseApi.setAppNo(appNo);
        BaseApi.setToken(2);
        return HttpUtil.post(`${api.getUserInfo}`, params);
    },
    getNoLoginLoanState(params,appNo){
        BaseApi.setAppNo(appNo);
        BaseApi.setToken(2);
        let headers = {
            'Content-Type':'application/x-www-form-urlencoded',
        };

        return HttpUtil.post(`${api.getLoanState}`, qs.stringify(params),{headers});
    },
    getBill(params,appNo){
        BaseApi.setAppNo(appNo);
        BaseApi.setToken(2);
        let headers = {
            'Content-Type':'application/x-www-form-urlencoded'
        };
        return HttpUtil.post(`${api.getBill}`, qs.stringify(params),{headers});
    },
    createOrder(params,appNo){
        BaseApi.setAppNo(appNo);
        BaseApi.setToken(2);
        return HttpUtil.post(`${api.createOrder}`, params);
    },
    checkSignature(params,appNo){
        BaseApi.setAppNo(appNo);
        BaseApi.setToken(2);
        return HttpUtil.post(`${api.checkSignature}`, params);
    },
    queryState(params,appNo){
        BaseApi.setAppNo(appNo);
        BaseApi.setToken(1);
        let headers = {
            'Content-Type':'application/x-www-form-urlencoded'
        };
        return HttpUtil.post(`${api.queryState}`, qs.stringify(params),{headers});

    }
}

export default noLogin;
