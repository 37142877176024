import HttpUtil from '@/utils/request'; // 导入http中创建的axios实例
import api from "@/api/api";

const LanaplusApi = {
    //获取用户信息
    getUserInfo(params){
        return HttpUtil.post(`${api.getUserInfo}`+"/"+params.customerId, params);
    },
    //获取最近一笔贷款信息
    getLatestInfo(phone){
        return HttpUtil.get(`${api.getLatestInfo}`+"/"+phone);
    },
    //还款
    repayment(params){
        return HttpUtil.post(`${api.repayment}`, params);
    },

    //获取最近一笔贷款信息
    test(custno){
        return HttpUtil.get(`${api.test}`+custno);
    },

}

export default LanaplusApi;
