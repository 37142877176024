<template>
   <div class="box">
       <van-radio-group v-model="selectBank" style="margin-top: 30px;margin-bottom: 20px;width: 80%;">
           <div class="div_row">
               <div class="div_row" style="flex: 1">
                   <van-image :src="require('@/assets/logo_spei.png')" class="bank_img"/>
               </div>
               <div class="div_row"  style="flex: 1">
                   <div style="display: flex;flex-direction:column;font-size: 14px;color: #888;align-items: flex-end;justify-content: center;margin-left: 20px;">
                       <span>{{$t("home.conision_no")}}</span>
                       <span>Horario:24/7</span>
                   </div>
                   <van-radio name="1" checked-color="#21AE5B" style="padding:0px 10px 0px 0px;margin-left: 10px;" @click="uploadRadioPoint(1)">
                   </van-radio>
               </div>
           </div>

           <div class="div_row" style="margin-top: 30px;">
               <div class="div_row" style="flex: 1">
                   <van-image :src="require('@/assets/logo_oxxo.png')" class="bank_img"/>
               </div>
               <div class="div_row"  style="flex: 1">
                   <div style="display: flex;flex-direction:column;font-size: 14px;color: #888;align-items: flex-end;justify-content: center;margin-left: 20px;">
                       <span>{{$t("home.conision_si")}}</span>
                       <span>Horario:24/7</span>
                   </div>
                   <van-radio name="2" checked-color="#21AE5B" style="padding:0px 10px 0px 0px;margin-left: 10px;" @click="uploadRadioPoint(2)">
                   </van-radio>
               </div>
           </div>
       </van-radio-group>

       <van-button class="btn" @click="goRepayment()">
           {{$t("common.btn")}}
       </van-button>
   </div>
</template>

<script>
    import {pagePoint} from "@/utils/PagePoint";
    const EVENT_ID = "h5_loan_repay";

    export default {
        name: "BankPopup",
        props:{
            repayInfo:{
                type:Object,
                default() {
                    return {//还款数据
                        custNo:"",
                        loanNo :"",
                        paymentMethod:"STP",
                        repayDate:"",
                        repayInstNum:"",
                        transAmt:"",
                        isOverdue:false,//是否逾期
                        rollOverAble:false,//是否展期
                        //展期数据
                        rollOverDetails:{
                            rollOverAmt:"",
                            rollOverDay:"",
                            rollOverRepayDay:"",
                        }
                    };
                }
            },
            rollOverAble:{
                type:Boolean,
                default() {
                    return false;
                }
            }
        },
        data(){
            return{
                selectBank:"1",
            }
        },
        methods:{
            uploadRadioPoint(index){
                if(index===1){
                    this.repayInfo.paymentMethod = "STP";
                    pagePoint(EVENT_ID,"click_STP","click",(new Date()).valueOf());
                }else{
                    this.repayInfo.paymentMethod = "CONEKTA";
                    pagePoint(EVENT_ID,"click_OXXO","click",(new Date()).valueOf());
                }
            },
            goRepayment(){
                this.repayInfo.rollOverAble = this.rollOverAble;
                console.log("还款数据:",this.repayInfo);
                pagePoint(EVENT_ID,"click_continue","click",(new Date()).valueOf());
                if(this.selectBank==="1"){
                    //保存到vuex
                    this.$router.push({path: '/bankrepayment'});
                }else{
                    this.$router.push({path: '/oxxoRepayment'});
                }
            }
        }
    }
</script>

<style scoped>
    .box{
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    .div_row{
        display: flex;
        flex-direction: row;
        width: 100%;
        font-font: Roboto-Medium;
    }
    .div_col{
        display: flex;
        flex-direction: column;
        width: 100%;
        font-font: Roboto-Medium;
    }
    .bank_img{
        width: 100px;
        height: 40px;
    }
    .btn{
        width: 80%;
        height: 40px;
        margin-top:20px;
        font-size: 14px;
        font-weight: 600;
        border: 0px;
        color: #3D3D3D;
        font-family: "Roboto-Medium";
        display: flex;
        justify-content: center;
        align-items: center;
        background: #FAD21E;
        border-radius: 5px;
    }
</style>
