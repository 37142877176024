import axios from 'axios';
import router from '@/router';
import store from '../vuex/index';
import {Toast} from "vant";
import AppConfig from "@/config/appconfig";
import i18n from "@/i18n";
/*第一层if判断生产环境和开发环境*/
// if (process.env.NODE_ENV === 'production') {
//     /*第二层if，根据.env文件中的VUE_APP_FLAG判断是生产环境还是测试环境*/
//     if (process.env.VUE_APP_FLAG === 'pro') {
//         //production 生产环境
//         axios.defaults.baseURL = 'http://test-appa.quantstack.in';
//     } else {
//         //test 测试环境
//         axios.defaults.baseURL = 'http://test-appa.quantstack.in';
//     }
// } else {
//     //dev 开发环境
//     axios.defaults.baseURL = 'http://test-appa.quantstack.in';
// }


/**
 * 提示函数
 * 禁止点击蒙层、显示一秒后关闭
 */
const tip = msg => {
    Toast({
        message: msg,
        duration: 2000,
        forbidClick: true
    });
}

/**
 * 跳转登录页
 * 携带当前页面路由，以期在登录页面完成登录后返回当前页面
 */
const toLogin = () => {
    console.log("401");
    let url = window.location.href;
    console.log(url)
     if(url.indexOf("/fb/payment/login")!==-1){
        //r3正常还款
        router.push({path:'/fb/payment/login',replace: true});
    }else if(url.indexOf("/payment/login")!==-1){
        //cshtm正常还款
        router.push({path:'/payment/login',replace: true});
    }else if(url.indexOf("/fb/payment/")!==-1){
        //r3免登录
        router.push({path:'/fb/payment',replace: true});
    }else if(url.indexOf("/payment/")!==-1){
        //cashtm免登录
        router.push({path:'/payment',replace: true});
    }else if(url.indexOf("/extension/")!==-1){
        //cashtm展期+正常还款

        router.push({path:'/extension',replace: true});
    }
};

/**
 * 请求失败后的错误统一处理
 * @param {Number} status 请求失败的状态码
 */
const errorHandle = (status, other) => {
    // 状态码判断
    switch (status) {
        // 401: 未登录状态，跳转登录页
        case 401:
            toLogin();
            break;
        // 403 token过期
        // 清除token并跳转登录页
        case 403:
            tip('Wrong parameter');
            localStorage.removeItem('token');
            setTimeout(() => {
                toLogin();
            }, 1000);
            break;
        // 404请求不存在
        case 404:
            tip('The requested resource does not exist');
            break;
        default:
            console.log(other);
    }
};

// 创建axios实例
var instance = axios.create({timeout: 1000 * 30});
// 设置post请求头
instance.defaults.headers['Content-Type'] = 'application/json';
//设置请求头参数
instance.defaults.headers['X-App-Name'] = AppConfig.appName;
instance.defaults.headers['X-App-No'] = AppConfig.appNo;
instance.defaults.headers['X-App-Version'] = AppConfig.versionNo;
instance.defaults.headers['X-App-Type'] = AppConfig.appType;
instance.defaults.headers['X-User-Language'] =i18n.locale;


/**
 * 请求拦截器
 * 每次请求前，如果存在token则在请求头中携带token
 */
instance.interceptors.request.use(
    config => {
        // 登录流程控制中，根据本地是否存在token判断用户的登录情况
        // 但是即使token存在，也有可能token是过期的，所以在每次的请求头中携带token
        // 后台根据携带的token判断用户的登录情况，并返回给我们对应的状态码
        // 而后我们可以在响应拦截器中，根据状态码进行一些统一的操作。
        store.commit('showLoading');
        //判断url中是否有?
        // if (config.url.indexOf("?")!==-1){
        //
        //     config.url = config.url+"&lang="+i18n.locale;
        // }else{
        //     config.url = config.url+"?lang="+i18n.locale;
        // }
        let token = localStorage.getItem("token");
        //设置token
        if(token) {
            config.headers['X-Auth-Token'] = 'Bearer ' + token;
        }
        return config;
    },
    error => {
        store.commit('hideLoading')
        tip("request timeout!");
        Promise.error(error);
    });

// 响应拦截器
instance.interceptors.response.use(
    // 请求成功
    res => {
        store.commit('hideLoading');
        store.commit('changeNetwork', true);
        if(res.status === 200){
            let data = res.data;
            if(data!==null){
                if(Object.prototype.hasOwnProperty.call(data, 'errorCode')){
                   console.log("错误码：-->"+data.errorCode)
                    if(data.errorCode===0){
                        return data;
                    }else if(data.errorCode===401){
                        tip("Login expired, please login again");
                        console.log("Login expired, please login again")
                        localStorage.removeItem('token');
                        setTimeout(() => {
                            toLogin();
                        }, 1000);
                    }else{
                        if(data.message){
                            tip(data.message);
                        }else{
                            tip("Unknown exception");
                        }
                        return "";
                    }
                }else{

                    return data;
                }
            }else{
                return data;
            }
        }else{
            tip("Unknown exception");
            return "";
        }
    },
    // 请求失败
    error => {
        store.commit('hideLoading')
        const { response } = error;
        if (response) {
            store.commit('changeNetwork', true);
            // 请求已发出，但是不在2xx的范围
            errorHandle(response.status, response.data.message);
            return Promise.reject(response);
        } else {
            // 处理断网的情况
            // eg:请求超时或断网时，更新state的network状态
            // network状态在app.vue中控制着一个全局的断网提示组件的显示隐藏
            // 关于断网组件中的刷新重新获取数据，会在断网组件中说明
            store.commit('changeNetwork', true);
            return "error";
        }
    });

export default instance;
