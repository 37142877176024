// const APIService = "https://test-api.quantx.mx";//生产地址
const APIService = "https://api.quantx.mx";//生产地址

const api = {
    sendCode:APIService+"/api/cust_info/cust/phone/code",//获取验证码
    login:APIService+"/api/cust_info/cust/login",//登录
    signInFree:APIService+"/api/loan_india/anon/signInFree",//免登录还款
    checkCode:APIService+"/api/cust_info/cust/anon/verify/captcha",//谷歌验证码替换,
    getBill:APIService+"/api/trade/fin/repay/bill",//获取还款账单
    getLoanState:APIService+"/api/loan_info/latest",//最近一笔贷款信息
    getExtension:APIService+"/api/trade/fin/get_repay_bill_rollover",//获取展期信息
    createOrder:APIService+"/api/trade/fin/repay",//创建订单
    createCashFreeOrder:APIService+"/api/trade/fin/cashFreeRepay",//创建CashFreeOrder订单
    checkSignature:APIService+"/repay/razorpay/state",//验证签名
    queryState:APIService+"/api/trade/fin/repay/state",//查询还款状态
    extensionCreateOrder:APIService+"/api/trade/fin/defer_repayment",//展期还款创建订单
    //获取用户信息
    getUserInfo:APIService+"/api/h5/login",//api/h5/login/{customerId}
    //获取最近一笔贷款信息
    getLatestInfo:APIService+"/api/h5/latest",//api/h5/latest/{phoneNo}
    //还款
    repayment:APIService+"/api/h5/repay",
    //测试获取custID
    test:APIService+"/api/h5/anon/id/",
}
export default api;
