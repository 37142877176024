import HttpUtil from '@/utils/request'; // 导入http中创建的axios实例
import api from "@/api/api";
import qs from "qs";
import BaseApi from "@/api/base";
// import qs from "qs";
const repayment = {
    getLoanState(params,appNo){
        BaseApi.setAppNo(appNo);
        BaseApi.setToken(1);
        let headers = {
            'Content-Type':'application/x-www-form-urlencoded',
        };
        return HttpUtil.post(`${api.getLoanState}`, qs.stringify(params),{headers});
    },

    getBill(params,appNo){
        BaseApi.setAppNo(appNo);
        BaseApi.setToken(1);
        let headers = {
            'Content-Type':'application/x-www-form-urlencoded'
        };
        return HttpUtil.post(`${api.getBill}`, qs.stringify(params),{headers});
    },
    getExtension(loanNo,appNo){
        BaseApi.setAppNo(appNo);
        BaseApi.setToken(1);
        return HttpUtil.get(`${api.getExtension}?loanNo=`+loanNo);
    },
    createOrder(params,appNo){
        BaseApi.setAppNo(appNo);
        BaseApi.setToken(1);
        return HttpUtil.post(`${api.createOrder}`, params);
    },
    //展期还款创建订单
    createExtensionOrder(params,appNo){
        BaseApi.setAppNo(appNo);
        BaseApi.setToken(1);
        return HttpUtil.post(`${api.extensionCreateOrder}`, params);
    },
    checkSignature(params,appNo){
        BaseApi.setAppNo(appNo);
        BaseApi.setToken(1);
        return HttpUtil.post(`${api.checkSignature}`, params);
    },
    queryState(params,appNo){
        BaseApi.setAppNo(appNo);
        BaseApi.setToken(1);
        let headers = {
            'Content-Type':'application/x-www-form-urlencoded'
        };
        return HttpUtil.post(`${api.queryState}`, qs.stringify(params),{headers});

    },
    createCashFreeOrder(params,appNo){
        BaseApi.setAppNo(appNo);
        BaseApi.setToken(1);
        return HttpUtil.post(`${api.createCashFreeOrder}`, params);
    },
}
export default repayment;
