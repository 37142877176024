import Vue from 'vue'
import VueI18n from 'vue-i18n'
Vue.use(VueI18n);
import en from "@/i18n/languages/en";
import zh from "@/i18n/languages/zh";
// 注册i18n实例并引入语言文件
const i18n = new VueI18n({
    locale: 'en',
    messages: {
        en,
        zh
    }
})

export default i18n
